import { Heading, Flex, Text, Skeleton } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useSWRImmutable from 'swr/immutable'

import { IconCardData } from '../IconCard'
import Buysec from '../Banners/Buysec'
import Investment from '../../../../../public/images/Investment.png'
import Icon1 from '../../../../../public/images/icon-1.png'
import Icon2 from '../../../../../public/images/icon-2.png'
import Icon3 from '../../../../../public/images/icon-3.png'

const Stats = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {}

  const TradesCardData: IconCardData = {}

  const StakedCardData: IconCardData = {}

  return (
    <Flex className="investBox" justifyContent="center" alignItems="center" flexDirection="column">
      {/* <Buysec /> */}
      <Heading color="var(--heading-color)" textAlign="center" scale="xl">
        {t('Used by millions.')}
      </Heading>
      <Heading color="var(--heading-color)" textAlign="center" scale="xl" mb="32px">
        {t('Trusted with billions.')}
      </Heading>

      <div className="invest-sec">
        <div className="custom-row">
          <div className="custom-col">
            <img src={Investment.src} alt="investment" />
          </div>
          <div className="custom-col">
            <div className="inv-text">
              <p className="inv-para">
                {t(
                  'CannabisSwap is the first decentralized exchange for cannabis fans. Our community aims to be one of the best decentralized exchanges for cryptocurrency.',
                )}
              </p>
              <ul className="inv-list">
                <li>
                  <img src={Icon1.src} alt="icon" />
                  <div>
                    <span>1 {t('Million')}</span> User
                    <p>{t('Within 30 Days')}</p>
                  </div>
                </li>
                <li>
                  <img src={Icon2.src} alt="icon" />
                  <div>
                    <span>1.2 {t('Million')}</span> Trades
                    <p>{t('Within 30 Days')}</p>
                  </div>
                </li>
                <li>
                  <img src={Icon3.src} alt="icon" />
                  <div>
                    <span>€30 {t('Million')}</span> Staking
                    <p>{t('Total Value Aimed')}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Flex>
  )
}

export default Stats
