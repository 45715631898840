import { appearAnimation, useIsomorphicEffect, useMatchBreakpoints } from '@pancakeswap/uikit'
import Link from 'next/link'
import { useWeb3React } from '@pancakeswap/wagmi'
import { useState } from 'react'
import styled from 'styled-components'
// import Image from 'next/image'
import SwiperCore from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import { Swiper } from 'swiper/react'
import { useTranslation } from '@pancakeswap/localization'
import BannerImg from '../../../../../public/images/banner-img.png'
import BannerImgLight from '../../../../../public/images/light-banner-img.png'
import AnimCoin from '../../../../../public/images/community-cannabis.png'
import AnimCoin1 from '../../../../../public/images/community-cannabis.png'
import AnimCoin2 from '../../../../../public/images/community-cannabis.png'
import { useMultipleBannerConfig } from './hooks/useMultipleBannerConfig'

// import Coin from '../../../../../public/images/coin-left.png'

const BannerPlaceHolder = styled.div<{ walletConnected: boolean }>`
  ${({ theme }) => theme.mediaQueries.sm} {
    height: 221px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    height: 232px;
  }

  @media (min-width: 768px) {
    .br-block {
      display: block;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-top: ${({ walletConnected }) => (walletConnected ? '190px' : '-32px')};
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: ${({ walletConnected }) => (walletConnected ? '90px' : '-32px')};
    margin-bottom: ${({ walletConnected }) => (walletConnected ? '40px' : '30px')};
  }
  ${({ theme }) => theme.mediaQueries.lg},${({ theme }) => theme.mediaQueries.md} {
    padding-top: 0;
    margin-top: ${({ walletConnected }) => (walletConnected ? '60px' : '-32px')};
    margin-bottom: ${({ walletConnected }) => (walletConnected ? '60px' : '30px')};
  }
`

const StyledSwiper = styled(Swiper)`
  position: relative;
  overflow: visible;
  opacity: 0;
  animation: ${appearAnimation} 0.3s ease-in-out 0.7s forwards;
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 108px;
    bottom: 12px;
    ${({ theme }) => theme.mediaQueries.sm} {
      bottom: 35px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
      bottom: 45px;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      bottom: 35px;
    }
  }
  .swiper-pagination-bullet {
    background-color: white;
    flex-basis: 108px;
    margin: 0 !important;
    border-radius: 0px;
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
`

const MultipleBanner: React.FC<React.PropsWithChildren> = () => {
  const bannerList = useMultipleBannerConfig()
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const [swiperRef, setSwiperRef] = useState<SwiperCore>(null)

  useIsomorphicEffect(() => {
    if (swiperRef) {
      if (bannerList.length > 1 && !swiperRef.autoplay?.running) {
        swiperRef.autoplay?.start()
      } else if (bannerList.length <= 1 && swiperRef.autoplay?.running) {
        swiperRef.autoplay?.stop()
      }
    }
  }, [bannerList, swiperRef])

  return (
    <>
      {/* <Image className="coin-left" src={Coin} /> */}

      <BannerPlaceHolder walletConnected>
        <div className="banner-sec">
          <div className="custom-row">
            <div className="custom-col">
              <h1>
                {/* <small className="custom-font">{t('YOUR GATEWAY TO DEFI')}</small> */}
                <span className="br-block"> </span>
                <span className="br-block"> </span>
                {t('BUY CBC, BITCOIN & CRYPTO')}
                {/* {t('& CRYTPO')} */}
                <span className="br-block"> </span>
                <Link href="/swap">
                  <button type="button" className="btn btn-default custombtn mt-40">
                    {t('Buy crypto with 10 €')}
                  </button>
                </Link>
                {/* <small className="custom-font1">TVL : $ 18,460,174</small> */}
              </h1>
            </div>
            <div className="custom-col">
              <div className="anim-coin">
                <img className="animCoin1" src={AnimCoin.src} alt="anim-coin" />
                <img className="animCoin2" src={AnimCoin1.src} alt="anim-coin" />
                <img className="animCoin3" src={AnimCoin2.src} alt="anim-coin" />
                <img
                  className="coin-img"
                  src={BannerImg.src}
                  alt="banner-coin"
                  style={{ display: 'var(--home-banner-dark-image)' }}
                />
                <img
                  className="coin-img"
                  src={BannerImgLight.src}
                  alt="banner-coin"
                  style={{ display: 'var(--home-banner-light-image)' }}
                />
              </div>
            </div>
          </div>
        </div>
      </BannerPlaceHolder>
    </>
  )
}

export default MultipleBanner
