import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'

import { Flex, Heading, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import { useWeb3React } from '@pancakeswap/wagmi'
import CoinHand from '../../../../public/images/coin-in-hand.png'
import Coin from '../../../../public/images/dollar-coin.png'
import SunburstSvg from './SunburstSvg'
import CompositeImage from './CompositeImage'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FloatingPancakesWrapper = styled(Container)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`

const BottomRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
`

const topLeftImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '1-bottom', alt: 'Pancake flying on the bottom' },
    { src: '1-left', alt: 'Pancake flying on the left' },
    { src: '1-top', alt: 'Pancake flying on the top' },
  ],
}

const bottomRightImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '2-bottom', alt: 'Pancake flying on the bottom' },
    { src: '2-top', alt: 'Pancake flying on the top' },
    { src: '2-right', alt: 'Pancake flying on the right' },
  ],
}

const Footer = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { isTablet, isDesktop } = useMatchBreakpoints()

  return (
    <>
      <BgWrapper style={{ display: 'none' }}>
        <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
          {/* <StyledSunburst /> */}
        </Flex>{' '}
        <BottomRightImgWrapper> </BottomRightImgWrapper>
      </BgWrapper>
      {(isTablet || isDesktop) && false && (
        <FloatingPancakesWrapper>
          <BottomRightImgWrapper> </BottomRightImgWrapper>
        </FloatingPancakesWrapper>
      )}
      <Wrapper className="seconds-wrapper">
        <img className="hand-coin" src={CoinHand.src} alt="coin" />
        <div>
          <Heading mb="24px" scale="xl" color="var(--heading-color)">
            {t('Start in seconds.')}
          </Heading>
          <Text textAlign="center" color="var(--text-color)">
            {t('Connect your crypto wallet to start using the app in seconds.')}
          </Text>
          <Text mb="24px" mt="24px" bold color="var(--yellow-text)">
            {t('No registration needed.')}
          </Text>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!account && <ConnectWalletButton />}
            <Link href="/blog">
              <a className="learnLink" style={{ color: 'var(--yellow-text)', fontWeight: 'bold' }}>
                {t('Learn how to start')}
              </a>
            </Link>
          </div>
        </div>
        <img className="machine-coin" src={Coin.src} alt="coin" />
      </Wrapper>
    </>
  )
}

export default Footer
