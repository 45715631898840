import styled from 'styled-components'

import PageSection from 'components/PageSection'
import { useWeb3React } from '@pancakeswap/wagmi'
import Head from 'next/head'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { PageMeta } from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChainId } from '@pancakeswap/sdk'
import Link from 'next/link'
import Image from 'next/image'
import Thumb1 from '../../../public/images/thumb1.png'
import Thumb2 from '../../../public/images/thumb2.png'
import Machine from '../../../public/images/machine.png'
import Lapt from '../../../public/images/lapt.png'
import Coins from '../../../public/images/coins.png'
// import * as styles from '../../styles/globals.csss'

import Hero from './components/Hero'
import { swapSectionData, earnSectionData, cakeSectionData } from './components/SalesSection/data'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import WinSection from './components/WinSection'
import FarmsPoolsRow from './components/FarmsPoolsRow'
import Footer from './components/Footer'
import CakeDataRow from './components/CakeDataRow'
import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
import UserBanner from './components/UserBanner'
import MultipleBanner from './components/Banners/MultipleBanner'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    // padding-top: 48px;
  }
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()
  const { chainId } = useActiveChainId()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '1400px' }

  const { t } = useTranslation()

  return (
    <>
      <PageMeta />
      {/* <style jsx global>{`
      body {
        background: red;
      }
      `}</style> */}
      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        {/* <Image className="coin-left" src={Coin} /> */}
        <MultipleBanner />
        <Hero />
      </StyledHeroSection>

      <MetricsSection />

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        index={2}
        hasCurvedDivider={false}
        containerProps={{
          id: 'home-9',
        }}
      >
        <div className="custom-row cards-row">
          <div className="custom-col">
            <div className="custom-card">
              <div className="img-box">
                <img src={Thumb1.src} alt="cart-thumb" />
              </div>
              <div className="card-cont">
                <h3>
                  Trade anything. No registration,<span className="br-block"> </span> no hassle.
                </h3>
                <p>
                  Trade any token on BNB Smart Chain in seconds, <span className="br-block"> </span> just by connecting
                  your wallet.
                </p>
                <div className="card-btns">
                  <Link href="/blog">
                    <button type="button" className="custombtn">
                      Read More
                    </button>
                  </Link>
                  {/* <Link href="/blog">
                    <a style={{ color: '#FCBF31', fontWeight: 'bold', marginLeft: '20px' }}>{t('Learn')}</a>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="custom-col">
            <div className="custom-card">
              <div className="img-box">
                <img src={Machine.src} alt="cart-thumb" />
              </div>
              <div className="card-cont">
                <h3>
                  Earn passive income with <span className="br-block"> </span> crypto.
                </h3>
                <p>
                  CannabisSwap makes it easy to make your crypto <span className="br-block"> </span> work for you.
                </p>
                <div className="card-btns">
                  <Link href="/blog">
                    <button type="button" className="custombtn">
                      Read more
                    </button>
                  </Link>
                  {/* <Link href="/blog">
                    <a style={{ color: '#FCBF31', fontWeight: 'bold', marginLeft: '20px' }}>{t('Learn')}</a>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom-row row1">
          <div className="custom-col" style={{ width: '50%', paddingLeft: '100px' }}>
            <h2 style={{ fontSize: '40px', color: 'var(--heading-color)', marginBottom: '30px', lineHeight: '60px' }}>
              {t('Shop on JointStore')} <span className="br-block"> </span> {t('with CBC')}
            </h2>
            <p style={{ color: 'var(--text-color)', marginBottom: '30px' }}>{t('Join the store')}</p>
            <div className="card-btns">
              <Link href="https://jointstore.de">
                <button type="button" className="custombtn">
                  Shop
                </button>
              </Link>
              <Link href="/buy-cbc">
                <button type="button" className="border-btn" style={{ marginLeft: '15px' }}>
                  {t('Buy CBC')}
                </button>
              </Link>
            </div>
          </div>
          <div className="custom-col" style={{ width: '60%', display: 'flex', justifyContent: 'center' }}>
            <img className="machine-img" src={Lapt.src} alt="machine" />
          </div>
        </div>

        {/* <SalesSection {...earnSectionData(t)} /> */}
        {/* TODO: until we are enable fetch multi-chain farms */}
        {/* {chainId === ChainId.BSC && <FarmsPoolsRow />} */}
      </PageSection>
      {/* <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        containerProps={{
          id: 'home-3',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <WinSection />
      </PageSection> */}

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        className="seconds"
        index={2}
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection>
    </>
  )
}

export default Home
